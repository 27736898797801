<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Install",
  components: {Navbar},
  async mounted() {
    setTimeout(async () => {
      const websitesResponse = await this.$apollo.query({
        query: require('../queries/websites.graphql'),
      });
      if (websitesResponse.data.websites.length > 0) window.location.href = 'https://app.chatspell.co/dashboard';
    }, 1000);
  }
}
</script>

<template>
  <div>
    <Navbar/>
    <div class="mt-4 max-w-7xl mx-auto grid  ">

      <div class="bg-white border border-1 rounded-xl p-3 sm:p-5 m-2">

        <h2 class="font-semibold">Slack installation required</h2>

        <span
            class="text-sm">You need to install Chatspell app inside your workspace.</span><br/><br/>

        <a href="https://api.chatspell.co/authenticate"
           style="align-items:center;color:#fff;background-color:#4A154B;border:0;border-radius:56px;display:inline-flex;font-family:Lato, sans-serif;font-size:18px;font-weight:600;height:56px;justify-content:center;text-decoration:none;width:276px">
          <svg xmlns="http://www.w3.org/2000/svg" style="height:24px;width:24px;margin-right:12px"
               viewBox="0 0 122.8 122.8">
            <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a"></path>
            <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0"></path>
            <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d"></path>
            <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e"></path>
          </svg>
          Add to Slack</a>

      </div>
    </div>
  </div>
</template>

<style scoped>

</style>