import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChatWidget from "@/views/ChatWidget.vue";
import Login from "@/views/Login.vue";
import Authenticate from "@/views/Authenticate.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import Dashboard from "@/views/Dashboard.vue";
import Exchange from "@/views/Exchange.vue";
import Install from "@/views/Install.vue";
import Connect from "@/views/Connect.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/chats/:id',
        name: 'Chat',
        component: ChatWidget
    },
    {
        path: '/login',
        name: 'Exchange',
        component: Exchange
    },
    {
        path: '/authenticate',
        name: 'Authenticate',
        component: Authenticate
    },
    {
        path: '/terms',
        name: 'TermsOfService',
        component: TermsOfService
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/install',
        name: 'Install',
        component: Install
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Connect
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
