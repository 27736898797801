<template>
  <div class="p-4">
    WEB SITE TERMS OF USE
    <br /> These "Terms of Use" set forth the terms and conditions that apply to your use of chatspell.co (the "Web
    Site"). By using the Web Site (other than to read this page for the first time), you agree to comply with all of
    the Terms of Use set forth herein. The right to use the Web Site is personal to you and is not transferable to
    any other person or entity.
    <br />
    <br /> Copyrights and Trademarks
    <br /> A. All materials contained on the Web Site are Copyright 2023 Chatspell, LLC. All rights reserved.
    <br />
    <br /> B. No person is authorized to use, copy or distribute any portion the Web Site including related graphics,
    ECMAScript, JavaScript, CSS, audio, phone numbers, etc.
    <br />
    <br /> C. Chatspell, LLC. and other trademarks and/or service marks (including logos and designs) found on the
    Web Site are trademarks/service marks that identify Chatspell, LLC and the goods and/or services provided by
    Chatspell, LLC. Such marks may not be used under any circumstances without the prior written authorization of
    Chatspell, LLC.
    <br />
    <br /> Links to Third-Party Web Site
    <br /> Chatspell, LLC may provide hyperlinks to third-party web sites as a convenience to users of the Web Site.
    Chatspell, LLC does not control third-party web sites and phone numbers and is not responsible for the contents
    of any linked-to, third-party web sites or any hyperlink in a linked-to web site. Chatspell, LLC does not
    endorse, recommend or approve any third-party web site hyperlinked from the Web Site. Chatspell, LLC will have
    no liability to any entity for the content or use of the content available through such hyperlink.
    <br />
    <br /> No Representations or Warranties; Limitations on Liability
    <br /> The information and materials on the Web Site could include technical inaccuracies or typographical or
    spoken errors. Changes are periodically made to the information contained herein. Chatspell, LLC MAKES NO
    REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, ALL OF
    WHICH IS PROVIDED ON A STRICTLY "AS IS" BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY EXPRESSLY DISCLAIMS ALL
    WARRANTIES WITH REGARD TO ANY INFORMATION, MATERIALS OR GRAPHICS OR AUDIO ON THE WEB SITE AND VOICE PORTAL,
    INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
    UNDER NO CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE UNDER ANY THEORY OF RECOVERY, AT LAW OR IN
    EQUITY, FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, SPECIAL, DIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
    DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF USE OR LOST PROFITS), ARISING OUT OF OR IN ANY MANNER CONNECTED
    WITH THE USE OF INFORMATION OR SERVICES, OR THE FAILURE TO PROVIDE INFORMATION OR SERVICES, FROM THE WEB SITE.
    <br />
    <br /> Privacy Policy
    <br /> Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to
    understand how we collect, use, communicate, disclose and make use of personal information. The following
    outlines our privacy policy.<br /><br />


    <b>User data collected:</b><br />
    - Email - for sending notifications about account<br />

    <b>Websites-related data collected:</b><br />
    - Website domain - for identification<br />
    - Visitor name - for identification<br />
    - Visitor email - when visitor is gone, agent can communicate directly by email<br />
    - Visitor device user-agent property - agent can see what device visitor is using<br />
    - Visitor IP Address - for localization feature - agent can see from what country visitor is coming from<br />

    If user want to access, transfer or delete data, user can contact us at <a href="mailto:support@chatspell.co"
                                                                               class="cursor-pointer underline">support@chatspell.co</a>.


    <br />
    <br /> Before or at the time of collecting personal information, we will identify the purposes for which
    information is being collected.
    <br /> We will collect and use personal information solely with the objective of fulfilling those purposes
    specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or
    as required by law.
    <br /> We will only retain personal information as long as necessary for the fulfillment of those purposes.
    <br /> We will collect personal information by lawful and fair means and, where appropriate, with the knowledge
    or consent of the individual concerned.
    <br /> Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary
    for those purposes, should be accurate, complete, and up-to-date.
    <br /> We will protect personal information by reasonable security safeguards against loss or theft, as well as
    unauthorized access, disclosure, copying, use or modification.
    <br /> We will make readily available to customers information about our policies and practices relating to the
    management of personal information.
    <br /> We are committed to conducting our business in accordance with these principles in order to ensure that
    the confidentiality of personal information is protected and maintained.
    <br /> Changes to These Terms of Use
    <br /> Chatspell, LLC reserves the right to change these Terms of Use at any time by posting new Terms of Use at
    this location. You can send e-mail to Chatspell, LLC with any questions relating to these Terms of Use at info
    support@chatspell.co.
    <br />
  </div>
</template>

<script>
export default {
  name: "TermsOfService"
}
</script>

<style scoped></style>