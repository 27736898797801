import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient, split} from "apollo-boost";
import {createApolloProvider} from "@vue/apollo-option";
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from "apollo-link-ws";

// const URL = 'https://localhost:7296/';
const URL = process.env.NODE_ENV === 'development' ? 'https://localhost:7143/' : 'https://api.chatspell.co/';

function getHeaders(oldHeaders) {
    const headers = oldHeaders;
    // if (localStorage.getItem('accessToken') === null) {
    //     window.location.href = '/login';
    // }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('access_token'))
        localStorage.setItem('accessToken', urlParams.get('access_token'));
    const token = localStorage.getItem('accessToken');
    if (token)
        headers["Authorization"] = `Bearer ${token}`;
    return headers;
}

const httpLink = createUploadLink({
    uri: URL + 'graphql',
    fetch: (uri, options) => {
        options.headers = getHeaders(options.headers);
        return fetch(uri, options);
    },
})

const wsLink = new WebSocketLink({
    uri: (URL + 'graphql').replace('https', 'wss'),
    options: {
        reconnect: true,
    },
})

const link = split(
    ({query}) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
    },
    wsLink,
    httpLink
)

const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    connectToDevTools: true
})

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient
})

export {apolloProvider, apolloClient}