<template>

</template>

<script>
export default {
  mounted() {
    if (localStorage.hasOwnProperty('accessToken')) {
      this.$router.push('/login');
    } else {
      window.location.href = 'https://api.chatspell.co/authenticate'
    }
  }
}
</script>