<template>
  <div class=" h-full" :style="{ 'height': !started ? '100vh' : '' }" style="background-color: #111826">
    <div v-if="!started">
      <div class=" w-full  text-white text-center p-4">
        <h1>Support chat</h1>
        <div class="flex justify-center">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-16 h-16 my-2">
            <path fill-rule="evenodd"
              d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
              clip-rule="evenodd" />
          </svg>

        </div>
        <span class="text-sm">
          Questions? Chat with us - we're here to help!<br />
          <a href="https://chatspell.co?utm_source=widget" target="_blank"
            class="cursor-pointer hover:underline plausible-event-name=PoweredBy">Powered
            by FREE Chatspell.co</a>

        </span>
      </div>
      <div class="p-4">
        <input type="text" v-model="name" v-on:keyup.enter="startChat"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Name">
        <input type="email" v-model="email" v-on:keyup.enter="startChat"
          class="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Email">
        <input type="text" v-model="question" v-on:keyup.enter="startChat"
          class="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-16"
          placeholder="Your question" required>
        <button type="button" :disabled="name.length === 0 || email.length === 0 || question.length === 0"
          @click="startChat" :style="{ 'background-color': website.color }"
          class="disabled:opacity-75 disabled:cursor-not-allowed text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full mt-4">
          Start chat
        </button>

      </div>
    </div>
    <div v-if="started">
      <div class="w-full text-white text-center p-1 text-center flex justify-between fixed top-0"
        :style="{ 'background-color': website.color }">
        <div></div>
        <div>Live chat <span class="hover:underline cursor-pointer" @click="restartChat">(restart)</span></div>
        <div class="cursor-pointer" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>


      <div class="pb-4" style="overflow-y: scroll; margin-bottom: 56px; margin-top: 32px; " id="messages-list">
        <div v-for="message in sortedMessages" :key="message.messageId">
          <div class="pt-4 px-4 flex"
            :class="{ 'justify-start': !message.isFromVisitor, 'justify-end': message.isFromVisitor }">
            <div class=" p-2 rounded-xl" v-if="message.content.length > 0"
              :class="{ 'bg-gray-300': !message.isFromVisitor, 'bg-blue-500 text-white': message.isFromVisitor }">
              {{ message.content }}
            </div>

          </div>
        </div>
      </div>

      <div class="fixed bottom-0 w-full flex">
        <input type="text" class="bg-gray-200 text-gray-900 block w-full p-4 focus:outline-none"
          placeholder="Type message and press enter" required v-model="content" v-on:keyup.enter="postMessage()">
      </div>


    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { isLocalStorageAvailable } from "@/utils";

export default {
  name: "ChatWidget",
  data: () => ({
    started: false,
    name: '',
    email: '',
    question: '',
    content: '',
    conversation: {},
    messages: [],
    website: {
      color: '#0566FF',
      logoUrl: 'https://www.socialintents.com/images/logo-white.png',
      showBranding: true,
    },
    index: null,
    closeTimer: null,
    working: false
  }),
  computed: {
    sortedMessages() {
      const items = this.conversation.messages ?? [];
      items.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
      return items;
    }
  },
  // apollo: {
  //   website: {
  //     query: require('../queries/website.graphql'),
  //     variables() {
  //       return {
  //         id: this.$route.params.id,
  //       }
  //     },
  //   }
  // },
  async mounted() {
    const self = this;
    window.addEventListener('message', ({ data, source }) => {
      if (data.type === 'website')
        self.website = data.website;
    });
    const websiteId = this.$route.params.id;
    if (isLocalStorageAvailable()) {
      if (localStorage.getItem('conversation_secret_' + websiteId) !== null) {
        const conversation = (await this.$apollo.query({
          query: require('../queries/conversation.graphql'),
          variables: {
            secret: localStorage.getItem('conversation_secret_' + websiteId)
          }
        })).data['conversation'];
        if (conversation.status !== "ARCHIVED") {
          this.conversation = conversation;
          this.started = true;
          await this.$nextTick(() => {
            document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight;
          });
          const observer = this.$apollo.subscribe({
            query: require('../subscriptions/conversationUpdated.graphql'),
            variables: {
              secret: self.conversation.secret
            },
          })
          observer.subscribe({
            next(data) {
              console.log(data);
              self.conversation = data.data['conversationUpdated'];
            }
          })
        }
      }
    }
  },
  methods: {
    async restartChat() {
      const websiteId = this.$route.params.id;
      if (isLocalStorageAvailable()) {
        await this.$apollo.mutate({
          mutation: require('../mutations/closeConversation.graphql'),
          variables: {
            secret: localStorage.getItem('conversation_secret_' + websiteId),
          }
        })
        localStorage.removeItem('conversation_secret_' + websiteId);
      }
      document.location.reload();
    },
    async startChat() {
      const self = this;
      if (self.working) return;
      self.working = true;
      const websiteId = this.$route.params.id;
      this.conversation = (await this.$apollo.mutate({
        mutation: require('../mutations/createConversation.graphql'),
        variables: {
          websiteId: websiteId,
          name: self.name,
          email: self.email,
          initialMessageContent: self.question
        },
      })).data['createConversation'];
      if (isLocalStorageAvailable()) localStorage.setItem('conversation_secret_' + websiteId, this.conversation.secret);

      this.started = true;

      const observer = this.$apollo.subscribe({
        query: require('../subscriptions/conversationUpdated.graphql'),
        variables: {
          secret: self.conversation.secret
        },
      })

      observer.subscribe({
        next(data) {
          console.log(data);
          self.conversation = data.data['conversationUpdated'];
        }
      })

      self.working = false;
    },
    close() {
      window.top.postMessage('close', '*');
    },
    async postMessage(attachmentUrl = null, attachmentName = null, check = true, isFromVisitor = true) {
      if (attachmentUrl == null) if (this.content.length === 0) return;
      const self = this;
      if (check)
        if (self.working) return;
      self.working = true;
      const content = this.content;
      const variables = {
        secret: self.conversation.secret,
        content,
        isFromVisitor
      };
      this.content = '';
      if (attachmentUrl != null) variables.attachmentUrl = attachmentUrl;
      if (attachmentName != null) variables.attachmentName = attachmentName;
      const message = (await this.$apollo.mutate({
        mutation: require('../mutations/postMessage.graphql'),
        variables
      })).data['postMessage'];
      this.messages.push(message);
      this.content = '';
      try {
        this.$nextTick(() => {
          try {
            document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight;
          } catch (_) {

          }
        })
      } catch (_) {

      }
      self.working = false;
      if (this.website.chatTimeout > 0) {
        clearTimeout(this.closeTimer);
        this.closeTimer = setTimeout(() => {
          window.top.postMessage('close', '*');
        }, this.website.chatTimeout);
      }
    }
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
}

.app {
  height: 100%;
}

.dark {
  background-color: #2b2b2b;
}

.bg-gray-x {
  background-color: #F8FBFC;
}


@media screen and (min-height: 512px) {
  #messages-list {
    height: calc(100vh - 90px);
  }
}

@media screen and (max-height: 512px) {
  #messages-list {
    height: 422px;
  }
}
</style>