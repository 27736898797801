<script>
import Navbar from "@/components/Navbar.vue";
import Tutorial from "@/components/Tutorial.vue";

export default {
  name: "Dashboard",
  components: {Tutorial, Navbar},
  data() {
    return {
      website: null,
      channels: []
    }
  },
  async mounted() {
    try {
      const websitesResponse = await this.$apollo.query({
        query: require('../queries/websites.graphql'),
      });
      const channelsResponse = await this.$apollo.query({
        query: require('../queries/channels.graphql'),
      });
      this.channels = channelsResponse.data.channels;
      this.website = websitesResponse.data.websites[0];
    } catch (err) {
      window.location.href = 'https://app.chatspell.co/install';
    }

  },
  methods: {
    copyToClipboard() {
      const copyText = document.getElementById("sc");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$toast.success('Copied to clipboard');
    },
    async updateWebsite() {
      const website = this.website;
      await this.$apollo.mutate({
        mutation: require('../mutations/updateWebsite.graphql'),
        variables: {
          websiteId: website.websiteId,
          slackChannelId: website.slackChannelId
        }
      });
      this.$toast.success('Channel updated');
    },
    async disconnect() {
      const website = this.website;
      await this.$apollo.mutate({
        mutation: require('../mutations/disconnect.graphql'),
        variables: {
          websiteId: website.websiteId,
        }
      });
      window.location.href = 'https://chatspell.co';
    }
  }
}
</script>

<template>
  <Navbar/>
  <div v-if="website">
    <div class="mt-4 max-w-7xl mx-auto grid md:grid-cols-2 ">

      <div class="bg-white border border-1 rounded-xl p-3 sm:p-5 m-2">

        <h2 class="font-semibold">Slack Channel configuration</h2>

        <span
            class="text-sm">Select to which Slack channel Chatspell should send notifications about new conversations</span>

        <select @change="updateWebsite" v-model="website.slackChannelId" id="channels"
                class="mt-2 py-3 px-4 pr-9 block w-full border  border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 ">
          <option value="" selected>Choose channel</option>
          <option :value="channel.id" v-for="channel in channels">#{{ channel.name }}</option>
        </select>

        <button @click="disconnect" type="button"
                class="mt-3 py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
          Disconnect
        </button>
      </div>


      <div class="bg-white border border-1 rounded-xl p-3 sm:p-5 m-2" v-if="website.slackChannelId">


        <h2 class="font-semibold">Install code snippet</h2>
        <span class="text-sm">Chatspell Live Chat widget is a web component that is dynamically connected to your website. Paste following code in head section to install widget. </span>

        <textarea id="sc" readonly
                  class="py-3 mt-2 px-4 block w-full  border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 darkx:bg-slate-900 darkx:border-gray-700 darkx:text-gray-400"
                  rows="3"><script src="//api.chatspell.co/widget/{{website.websiteId}}" async></script></textarea>
        <button @click="copyToClipboard" type="button"
                class="mt-3 py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
          Copy
        </button>

      </div>

    </div>
    <div class="mx-auto max-w-7xl">
      <!--      <Tutorial />-->
    </div>
  </div>
  <div v-else class="mt-12 max-w-7xl mx-auto flex justify-center">
    <div
        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<style scoped>

</style>