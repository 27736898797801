import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {apolloProvider} from "@/graphql";
import ToastPlugin from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://da846711da7f0e3342bce98c50f96745@o337011.ingest.sentry.io/4505657270992896",
    integrations: [new Sentry.Replay(),],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});


app.use(apolloProvider)
    .use(ToastPlugin)
    .use(router)
    .mount('#app')
