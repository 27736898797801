<script>
export default {
  name: "Exchange",
  async mounted() {

    if (localStorage.hasOwnProperty('accessToken')) {
      this.$router.push('/dashboard');
    }

    const code = this.$route.query.code
    const state = this.$route.query.state

    if (code != null) {

      if (state === 'openid') {
        try {
          const websiteId = localStorage.getItem('websiteId');
          const response = await this.$apollo.mutate({
            mutation: require('../mutations/openIDLogin.graphql'),
            variables: {
              code, websiteId
            }
          });
          const token = response.data.openIDLogin;
          if (JSON.stringify(response).includes('No app installed')) {
            window.location.href = 'https://app.chatspell.co/install'
            return;
          }
          localStorage.setItem('accessToken', token);
        } catch (err) {
          console.log(err);
          window.location.href = 'https://app.chatspell.co/install'
          return;
        }
      } else {
        try {
          const response = await this.$apollo.mutate({
            mutation: require('../mutations/login.graphql'),
            variables: {
              code
            }
          });
          if (JSON.stringify(response).includes('No user found')) {
            window.location.href = 'https://app.chatspell.co/connect'
            return;
          }
          const token = response.data.login;
          localStorage.setItem('accessToken', token);
        } catch (err) {
          localStorage.setItem('websiteId', err.graphQLErrors[0].extensions.code);
          window.location.href = 'https://app.chatspell.co/connect';
          return;
        }
      }
    }
    this.$router.push('/dashboard');
  }
}
</script>

<template>

</template>

<style scoped>

</style>