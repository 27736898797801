<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Connect",
  components: {Navbar},
  async mounted() {
    const websitesResponse = await this.$apollo.query({
      query: require('../queries/websites.graphql'),
    });
    if (websitesResponse.data.websites.length > 0) window.location.href = 'https://app.chatspell.co/dashboard';
  }
}
</script>

<template>
  <div>
    <Navbar/>
    <div class="mt-4 max-w-7xl mx-auto grid  ">

      <div class="bg-white border border-1 rounded-xl p-3 sm:p-5 m-2">

        <h2 class="font-semibold">Slack account connection required</h2>

        <span
            class="text-sm">You need to Sign-In with Slack in order to proceed.</span><br/><br/>

        <a href="https://slack.com/openid/connect/authorize?state=openid&client_id=4988886068833.4999957200144&redirect_uri=https://app.chatspell.co/login&response_type=code&scope=openid"
           style="align-items:center;color:#000;background-color:#fff;border:1px solid #ddd;border-radius:4px;display:inline-flex;font-family:Lato, sans-serif;font-size:16px;font-weight:600;height:48px;justify-content:center;text-decoration:none;width:256px">
          <svg xmlns="http://www.w3.org/2000/svg" style="height:20px;width:20px;margin-right:12px"
               viewBox="0 0 122.8 122.8">
            <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a"></path>
            <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0"></path>
            <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d"></path>
            <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e"></path>
          </svg>
          Sign in with Slack</a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>